import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, prop?: string, propValue?: string | boolean | null): any[] {
    if (!items) return [];
    // if (!searchText) return items;
    return items.filter((item, index) => {
      return Object?.keys(item).some(key => {
        let result: boolean = false;
        if (searchText) {
          result = String(item[key]).toLowerCase().includes(searchText?.toLowerCase())
        } else {
          result = true;
        }

        if (prop && (propValue == true || propValue == false || propValue == null)) {
        }

        return result;
      });
    });
  }
}
